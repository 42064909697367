/* CompanyForm.css */
.company-form-container {
  background-color: #f4f6f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: auto;
  font-family: Arial, sans-serif;
}

.company-form {
  display: flex;
  flex-direction: column;
}

.company-form input {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.image-upload {
  margin: 20px 0;
}

.image-upload label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.image-preview-container {
  text-align: center;
  margin-top: 10px;
}

.image-preview-container h3 {
  margin-bottom: 10px;
  font-size: 16px;
}

.image-preview-container img {
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
}

button {
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .company-form-container {
    padding: 15px;
    margin: 0 10px;
  }

  .company-form input {
    font-size: 14px;
    padding: 8px;
  }

  .image-preview-container img {
    max-width: 150px;
  }

  button {
    font-size: 14px;
    padding: 10px;
  }
}

/* Adjust the title font size */
.company-form .image-preview-container h3 {
  font-size: 1.2rem; /* Smaller title */
  margin-bottom: 5px;
}

/* Make the images smaller */
.company-form .image-preview-container img.small-image {
  width: 100px; /* Smaller image size */
  height: auto;
  border-radius: 8px; /* Optional: Rounded corners */
}

/* Optional: Add some padding and adjust form layout */
.company-form {
  padding: 20px;
}

.company-form input[type="file"] {
  margin-top: 10px;
}
